<template>
    <b-alert :show="!$helpers.isBlank(error)" variant="danger" dismissible>
        <span v-html="error.html"></span>
    </b-alert>
</template>

<script>
    export default {
        props: ['error']
    }
</script>